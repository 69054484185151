<div class="wrapper">
  <div class="header-main">
    <div class="header-main__text">
      <h2 i18n="@@*span*We'd love*span* to hear from you!">
        <span>We'd love</span>
        <br />
        to hear from you!
      </h2>
      <div>
        <p class="l normal" i18n="@@Ready to start your next project with us? Contact our team to discuss your requirements and get a personalized solution tailored to your needs.">
          Ready to start your next project with us? Contact our team to discuss
          your requirements and get a personalized solution tailored to your
          needs.
        </p>
      </div>
    </div>
    <div class="background">
      <h1 i18n="@@Contact us">Contact us<br></h1>
    </div>
  </div>
  <div class="contact-form">
    <div class="information">
      <div class="description">
        <div class="list-and-photo">
          <div class="sails-photo border-custom middle" [style.background-image]="'url(' + salesPhotoUrl + ')'"></div>
          <div class="list-options">
            <h4 i18n="@@What Happens Next:">What Happens Next:</h4>
            <div class="options">
              <div class="list-container">
                <div class="list-item">
                  <div class="square"></div>
                  <p class="l normal" i18n="@@Our team will assess your project needs and provide you with a preliminary proposal and timeline.">Our team will assess your project needs and provide you with a preliminary proposal and timeline.<br></p>
                </div>
                <div class="list-item">
                  <div class="square"></div>
                  <p class="l normal" i18n="@@Based on our discussion and evaluation, we'll create a tailored solution to meet your specific needs.">
                    Based on our discussion and evaluation, we'll create a tailored
                    solution to meet your specific needs.
                  </p>
                </div>
                <div class="list-item">
                  <div class="square"></div>
                  <p class="l normal" i18n="@@Once the proposal is approved, we'll kick-start the development process, keeping you updated at every stage.">
                    Once the proposal is approved, we'll kick-start the development
                    process, keeping you updated at every stage.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <p class="l bold" i18n="@@Let's turn your ideas into reality! Fill out the form below or contact us directly to get started.">
          Let's turn your ideas into reality! Fill out the form below or contact
          us directly to get started.
        </p>
      </div>
    </div>
    <div class="form">
      <app-contact-form></app-contact-form>
    </div>
  </div>
  <app-our-contacts [persons]="persons"></app-our-contacts>
</div>
