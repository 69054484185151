import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ContactFormComponent } from '@components/contact-form/contact-form.component';
import { OurContactsComponent } from '@components/our-contacts/our-contacts.component';
import { BACKGROUND_COLORS } from '@const/backgroud-colors.const';
import { map, Observable } from 'rxjs';
import { Person } from 'src/app/interfaces/person.interface';
import { DataService } from 'src/app/services/data.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-contact-us-page',
  standalone: true,
  imports: [ContactFormComponent, OurContactsComponent, CommonModule],
  templateUrl: './contact-us-page.component.html',
  styleUrl: './contact-us-page.component.scss',
})
export class ContactUsPageComponent implements OnInit {
  public persons!: Observable<Person[]>;
  public salesPhotoUrl =
    environment.staticUrl + '/resources/pixel/images/sales-image.jpg';

  constructor(private dataService: DataService) {}

  ngOnInit(): void {
    const lang: string | null = localStorage.getItem('lang');
    if (lang) {
      this.persons = this.dataService.getFilteredPersons(lang, 'Sales').pipe(
        map((persons) =>
          persons.map((person, index) => ({
            ...person,
            color:
              index < BACKGROUND_COLORS.length
                ? BACKGROUND_COLORS[index]
                : BACKGROUND_COLORS[
                    index -
                      Math.floor(index / BACKGROUND_COLORS.length) *
                        BACKGROUND_COLORS.length
                  ],
          }))
        )
      );
    }
  }
}
